@import '~antd/dist/antd.css';

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
p { 
  margin: 0;
  padding: 0;
}

.nav-link {
  color: #46587c;
}

.nav-link:hover {
  color: #46587c;
}

.active {
  background-color: #64A64E;
  color: white;
}

.active:hover {
  color: white;
}